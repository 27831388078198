<template>
  <div>
    <div class="container">
      <div class="row">
        <div v-if="pessoaJuridica" class="col-8 offset-2">
          <h5 class="font-weight-light">
            <b>
              Olá {{ auth.getUserInfo().nomeFantasia }}, agora você é um
              parceiro Bike Registrada.</b
            >
          </h5>
          <h5 class="font-weight-light">
            <b>
              Segue abaixo os benefícios para sua loja.
            </b>
          </h5>
          <div class="mt-5">
            <p>
              <i class="fas fa-check text-success mr-2"></i>Vender seguro de
              bikes por assinatura para seus clientes de forma rápida, simples e
              barata.
            </p>
            <p>
              <i class="fas fa-check text-success mr-2"></i>Mostre sua loja para
              todo Brasil de forma rápida e online. Anuncie suas bikes para
              +400mil ciclistas em todo Brasil (basta clicar
              <a
                href="https://classificados.bikeregistrada.com.br"
                target="_blank"
                >aqui</a
              >)
            </p>
            <p>
              <i class="fas fa-check text-success mr-2"></i>Selo de segurança
              Bike Registrada. Mostre para seu cliente que você se preocupa com
              a segurança dele e é contra o comércio de bikes roubadas no
              Brasil. Fale com um de nossos representantes pelo telefone (61)
              3522-4521
            </p>
          </div>
        </div>
        <div v-else class="col-8 offset-2">
          <h5 class="font-weight-light">
            <b>
              Olá {{ auth.getUserInfo().nomeFantasia }}, agora você é um
              parceiro Bike Registrada.</b
            >
            <br />
          </h5>
          <h5 class="font-weight-light">
            <b>
              Veja abaixo os benefícios que temos para você!
            </b>
          </h5>
          <div class="mt-5">
            <p>
              <i class="fas fa-check text-success mr-2"></i> Ganhe dinheiro
              vendendo seguro para seus amigos do pedal.
            </p>
            <p>
              <i class="fas fa-check text-success mr-2"></i>Registre suas bikes
              e mostre que você se preocupa com a segurança no pedal
            </p>
            <p>
              <i class="fas fa-check text-success mr-2"></i>Compartilhe seu
              <router-link to="/dashboard/link-parceiro"> link </router-link>
              de indicação com sua galera em suas redes sociais, assim você pode
              vender e ganhar mais $$$
            </p>
            <p>
              <i class="fas fa-check text-success mr-2"></i>Use nosso
              <a
                href="https://classificados.bikeregistrada.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                >classificados e anuncie e compre bikes usadas</a
              >
              classificados e anuncie e compre bikes usadas ou novas com
              procedência
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import auth from "../../auth";

export default {
  components: {},
  data() {
    return {
      auth,
      config,
      pessoaJuridica: true,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (!userInfo.cnpj) this.$router.replace("/parceiro");
    if (userInfo.cnpj.length < 14) {
      this.pessoaJuridica = false;
    }
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
/* .h-line {
  background-color: #23ad21;
  height: 3px;
  width: 70%;
  margin: 0 auto;
} */

.plano-detalhes {
  margin-bottom: 0;
  padding-left: 24px;
  font-size: 14px;
}
</style>

<style></style>
